import React from "react";

import { Box, Typography, styled } from "@mui/material";
import Loader from "./AppLoader.web";
const LoginImage = require("./assets/LoginImage_.jpg");

const LoginEmailBoxTextCustom = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "100%",
  minHeight: "100vh",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const LoginEmailBox = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "50%",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  "& .login-main-image": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
  "& .login-overlay-image": {
    backgroundColor: "#0000007A",
    position: "absolute",
    inset: "0",
  },
  "& .forward-icon":{
   top:"50%",
   position:"absolute",
   backgroundColor:"white",
   borderRadius:"50%",
   padding:"10px"
  },
  "& .forward":{
    right:"5%",
  },
  "& .backward":{
    left:"5%",
  },
  "& .login-email-text-container": {
    position: "absolute",
    color: "#FFFFFF",
    textAlign: "center",
    zIndex: 1,
    left: "20px",
    bottom: "10%",
    right: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "& .login-email-main-text-1": {
      fontFamily: "Poppins, sans-serif",
      fontWeight: 700,
      fontSize: "38px",
      lineHeight: "46px",
      marginBottom: "18px",
      maxWidth: "530px",
      [theme.breakpoints.down("md")]: {
        fontSize: "34px",
        lineHeight: "44px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "30px",
        lineHeight: "38px",
        maxWidth: "430px",
      },
    },
    "& .login-email-main-text-2": {
      margin: 0,
      fontFamily: "Poppins, sans-serif",
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "20px",
      color: "#ffffff",
      width: "100%",
      maxWidth: "360px",
    },
  },
}));
interface ILoginSideImageWrapperProps {
  children: React.ReactNode;
  isLoading: boolean;
  vendorLogin?: boolean
}

const LoginSideImageWrapper: React.FC<ILoginSideImageWrapperProps> = ({
  children,
  isLoading,
  vendorLogin
}) => {
  return (
    <LoginEmailBoxTextCustom>
      <Loader isLoading={isLoading} />
      <LoginEmailBox>
        <img src={LoginImage} alt="" className="login-main-image" />
        <Box className="login-overlay-image" />
        <Box className="login-email-text-container">
          <Typography className="login-email-main-text-1">
            Plan your wedding hassle free with us
          </Typography>
          <Typography className="login-email-main-text-2">
            we crafted a unique wedding and an experience our gueststruly
            enjoyed.
          </Typography>
        </Box>
      </LoginEmailBox>
      {children}
    </LoginEmailBoxTextCustom>
  );
};

export default LoginSideImageWrapper;
