import React from "react";
import { Box, Typography, styled } from "@mui/material";

interface IRealWeddingCardProps {
  image_url_1: string;
  image_url_2: string;
  image_url_3: string;
  image_url_4: string;
  couple_name: string;
  location: string;
}

const RealWeddingCard: React.FC<IRealWeddingCardProps> = ({
  image_url_1,
  image_url_2,
  image_url_3,
  image_url_4,
  couple_name,
  location,
}) => {
  return (
    <RealWeddingCardStyle className="real-wedding">
      <Box className="real-wedding-one-image">
        <img src={image_url_1} className="real-wedding-image" alt="wedding" />
      </Box>
      <Box className="real-world-three-image-container">
        <Box className="real-wedding-second-container">
          <img src={image_url_2} className="real-wedding-image" alt="wedding" />
        </Box>
        <Box className="real-wedding-second-container">
          <img src={image_url_3} className="real-wedding-image" alt="wedding" />
        </Box>
        <Box className="real-wedding-second-container">
          <img src={image_url_4} className="real-wedding-image" alt="wedding" />
        </Box>
      </Box>
      <Box className="text-container">
        <Typography className="real-wedding-name" title={couple_name}>
          {couple_name}
        </Typography>
        <Typography className="real-wedding-location" title={location}>
          {location}
        </Typography>
      </Box>
    </RealWeddingCardStyle>
  );
};

export default RealWeddingCard;

const RealWeddingCardStyle = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  borderRadius: "8px",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  gap: "6px",
  boxShadow: "0px 4px 4px 0px #00000040",
  width: "100%",
  "& .real-wedding-one-image": {
    height: "305px",
    width: "100%",
  },
  "& .real-wedding-image": {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    backgroundPosition: "center",
  },
  "& .real-world-three-image-container": {
    gap: "6px",
    display: "flex",
  },
  "& .real-wedding-second-container": {
    flex: "1 1 auto",
  },
  "& .text-container": {
    padding: "10px 16px 20px",
  },
  "& .real-wedding-name": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "40px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color: "#292524",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .real-wedding-location": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    textAlign: "left",
    color: "#78716C",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
