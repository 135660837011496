import React from "react";

// Customizable Area Start
import { Box, Typography, styled, Container, Grid } from "@mui/material";

import Loader from "../../../components/src/AppLoader.web";
import ShoppingCard from "../../../components/src/ShoppingCard.web";
import HeaderFooterLayout from "../../../components/src/HeaderFooterLayout";

const photoLabImage = [
  "https://image.wedmegood.com/resized-nw/1300X/wp-content/uploads/2019/02/DzH2iFHU0AEENoE.jpg",
  "https://www.weddingplz.com/blog/wp-content/uploads/chanu-dawani-weddingplz-1.jpg",
  "https://cdn0.weddingwire.in/article/6812/original/960/jpg/122186-cinelove.webp",
  "https://wishnwed-blog-media.s3.ap-southeast-1.amazonaws.com/wordpress/uploads/2020/03/VIV8590-XL-751x500.jpg",
  "https://miro.medium.com/v2/resize:fit:720/format:webp/1*DdwfMICrmq_JFTGLrAiXVA.png",
  "https://cdn0.weddingwire.in/article/8612/3_2/960/jpg/122168-plushaffairs-cover.webp",
  "https://www.weddingsutra.com/wp-content/smita-nilesh-35.jpg",
  "https://image.wedmegood.com/resized-nw/1300X/wp-content/uploads/2019/02/51828482_648979678892539_5208062335371732650_n.jpg",
  "https://image.wedmegood.com/resized-nw/1300X/wp-content/uploads/2019/02/DzBiMb1VAAEdF2C.jpg",
];

const shoppingData = [
  {
    id: 1,
    title: "Wedding decorations",
    image_url:
      "https://www.thepackersmovers.com/blog/wp-content/uploads/2022/05/Latest-Home-Decoration-Ideas-for-Indian-Wedding.jpg",
    description:
      "Step into a world of elegance and romance at our breathtaking wedding venues.",
  },
  {
    id: 2,
    title: "Mehendi designs",
    image_url:
      "https://photosly.net/wp-content/uploads/2024/02/arabic-mehndi-design-easy-photo24.jpg",
    description:
      "Your wedding day is one of the most significant and memorable days of your life",
  },
  {
    id: 3,
    title: "Wedding photography",
    image_url:
      "https://kamatharjun.com/wp-content/uploads/2019/07/AV-wed15.jpeg",
    description:
      "Step into a world of elegance and romance at our breathtaking wedding venues",
  },
  {
    id: 4,
    title: "Wedding songs",
    image_url:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSX_jto6fR0WfN4Sw6HoT52UNowkMjKLVjXVom22m5EdzTAB3vTWFdDaWwUaw&s",
    description:
      "A destination wedding offers a unique and magical experience that combines your special day ",
  },
];

// Customizable Area End

import InspirationsItemController, { Props } from "./InspirationsItemController";

export default class InspirationsItem extends InspirationsItemController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <HeaderFooterLayout navigation={this.props.navigation}>
        <Loader isLoading={this.state.isLoading} />
        <CustomContainer>
          <InspirationItemStyle component="section" id="inspiration">
            <Typography className="header-inspiration" component="h3">
              Wedding Photography
            </Typography>
            <Typography className="description-inspiration">
              The perfect photographer to capture your special day. View each
              studio's portfolio to find your style!
            </Typography>
            <Box className="inspiration-main-image-container">
              <img
                src="https://image.wedmegood.com/resized/800X/uploads/images/9b143ea271394cb1b4616a846fcdcf79realwedding/AMIT7417.jpg"
                className="inspiration-main-image"
                alt="image"
              />
            </Box>
            <Typography className="photo-gallery-text" component="h3">
              Photo Gallery
            </Typography>
            <Grid className="gallery-container" container spacing={4}>
              {photoLabImage.map((url, index) => (
                <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                  <Box className="gallery-image-container">
                    <img src={url} alt="gallery" />
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Typography className="photo-gallery-text" component="h3">
              Browse more wedding ideas & tips
            </Typography>
            <Grid container spacing={3} className="inspiration-item-container">
              {shoppingData.map((data, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <ShoppingCard
                    id={data.id}
                    data-test-id="shopping-card"
                    image_url={data.image_url}
                    onCardClick={this.redirectToInspirationItemsPage}
                    title={data.title}
                    description={data.description}
                  />
                </Grid>
              ))}
            </Grid>
          </InspirationItemStyle>
        </CustomContainer>
      </HeaderFooterLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const InspirationItemStyle = styled(Box)(({ theme }) => ({
  padding: "56px 0 0",
  "& .header-inspiration": {
    fontFamily: "Poppins",
    color: "#44403C",
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "24px",
    textAlign: "center",
  },
  "& .description-inspiration": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    textAlign: "center",
    color: "#57534E",
    lineHeight: "26px",
    margin: "16px 0",
  },
  "& .inspiration-main-image-container": {
    width: "100%",
    height: "490px",
    "& .inspiration-main-image": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  "& .photo-gallery-text": {
    fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "24px",
    textAlign: "left",
    margin: "40px 0 30px",
  },
  "& .gallery-image-container": {
    height: "326px",
    width: "100%",
    "& img": {
      height: "100%",
      objectFit: "cover",
      width: "100%",
      borderRadius: "8px",
    },
  },
  "& .gallery-container": {
    marginBottom: "40px",
  },
  "& .inspiration-item-container": {
    marginTop: "-32px",
    "> .MuiGrid-item": {
      paddingTop: "32px",
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
  },
}));

const CustomContainer = styled(Container)({
  flex: "1 1 auto",
  "@media(min-width:1408px)": {
    maxWidth: "1408px !important",
  },
  "@media(min-width:1280px)": {
    maxWidth: "100%",
  },
});
// Customizable Area End
