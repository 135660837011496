import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  styled,
  InputLabel
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import { WeddingLogo } from "./assets";
import SelectField from '../../../components/src/SelectField.web'
import { Formik, Form } from "formik";
import * as Yup from "yup";
import LoginSideImage from "../../../components/src/LoginSideImageWrapper.web";
import TextInputFiled from "../../../components/src/TextInputField.web";

const validationLoginSchema = Yup.object({
    phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(/^\d+$/, 'Enter correct your phone number')
    .min(10, 'Phone number must be at least 10 digits')
    .max(10, 'Phone number cannot exceed 10 digits'),
    phoneId:Yup.string()
    .required('Phone number is required')
});
// Customizable Area End

import VendorPhoneLoginController, { Props } from "./VendorPhoneLoginController.web";

export default class VendorPhoneLogin extends VendorPhoneLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <LoginSideImage isLoading={this.state.isLoading} vendorLogin={true}>
        <MainContentBox data-test-id="MainContentBox">
          <Box className="sign-up-form-container">
            <Formik
              data-test-id="Formik"
              initialValues={this.state.formData}
              validationSchema={validationLoginSchema}
              onSubmit={(values) => this.goToOtpPage(values)}
            >
              {({
                touched,
                values,
                errors,
                setFieldValue,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => {
                return (
                  <Form onSubmit={handleSubmit} noValidate>
                    <img
                      src={WeddingLogo}
                      className="wedding-logo"
                      alt="Wedding Logo"
                      data-test-id="wedding-logo"
                      onClick={this.redirectFromVendorLoginToHomePage}
                    />
                    <Typography className = "sign-up-form-container-text-1">
                      Business Login
                    </Typography>
                    <Typography className="sign-up-form-container-text-2">
                      Get started for free
                    </Typography>
                    <InputLabel className="labelClass">Phone number</InputLabel>
                    <Box className="numberField">
                    <SelectField
                            placeholder="+91"
                            handleBlur={handleBlur("phoneId")}
                            label=""
                            name="phoneId"
                            options={this.state.phoneNumberData.map((data: any) => ({
                                label: data.value,
                                value: data.label,
                            }))}
                            setFieldValue={setFieldValue} value={values.phoneId} helperText={undefined} 
                    />
                     <TextInputFiled
                      name="phoneNumber"
                      value={values.phoneNumber}
                      placeholder="0000000000"
                      data-test-id="email-input"
                      handleChange={(e) => {
                        handleChange(e);
                        this.changePhoneValid();
                      }}
                      type="email"
                      handleBlur={handleBlur}
                      error={
                        this.state.invalidPhoneNumber
                          ? true
                          : touched.phoneNumber && !!errors.phoneNumber
                      }
                      helperText={
                        undefined
                      }
                    />
                    </Box>
                   <Typography className="errorText"> { this.state.invalidPhoneNumber
                          ? "Account not found or not activated"
                          : touched.phoneNumber && errors.phoneNumber
                      }</Typography>
                    <LoginButton
                      data-test-id="sign-up-button"
                      type="submit"
                      disabled={
                        this.state.invalidPhoneNumber
                      }
                      fullWidth
                    >
                      Login
                    </LoginButton>
                    <Typography className="emailLogin" onClick={this.goToEnailLogin}>Login with email</Typography>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </MainContentBox>
      </LoginSideImage>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContentBox = styled(Box)(({ theme }) => ({
  width: "50%",
  boxSizing: "border-box",
  height: "100%",
  padding: "30px 114.96px 30px 72.32px",
  overflowY: "auto",
  [theme.breakpoints.down("md")]: {
    height: "100%",
    minHeight: "fit-content",
    overflowY: "unset",
    width: "100%",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "30px 30px 68px",
  },
  "& .sign-up-form-container": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& form": {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        maxWidth: "600px",
      },
    },
  },
  "& .wedding-logo": {
    marginBottom: "24px",
    aspectRation: "1",
    height: "60px",
    width: "120.62px",
    cursor: "pointer"
  },
  "& .sign-up-form-container-text-1": {
    fontSize: "22px",
    color: "#000000",
    fontFamily: "Poppins",
    marginBottom: "6px",
    fontWeight: "600",
    lineHeight: "24px",
  },
  "& .sign-up-form-container-text-2": {
    fontFamily: "Poppins",
    marginBottom: "24px",
    fontWeight: "400",
    lineHeight: "24px",
    color: "#78716C",
    fontSize: "16px",
  },
  "& .errorText":{
   color:"#DC2626",
   fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: "400",
    lineHeight: "18px",
    marginTop:"-10px"
  },
  "& .emailLogin":{
    textAlign:"center",
    marginTop:"10px", 
    fontWeight: "500",
    lineHeight: "24px",
    color: "#57534E",
    fontFamily: "Poppins",
    fontSize: "12px",
    cursor:"pointer"
  },
  "& .numberField":{
    display:'flex',
    gap:'10px',
    width:"100%",
    marginTop:"16px",
    alignItems:'center',
    "& > *:nth-child(1)": {
        width:"15%",
    },
  },
  "& .labelClass": {
    color: "#334155",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "22px",
},
}));
const LoginButton = styled(Button)({
  borderRadius: "8px",
  padding: "19px 20px",
  textTransform: "unset",
  marginTop: "30px",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  border: "1px solid  #801187",
  color: "#FFFFFF !important",
  backgroundColor: "#801187",
  "&:hover": {
    backgroundColor: "#801187",
  },
});
// Customizable Area End
